exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-achievements-js": () => import("./../../../src/pages/achievements.js" /* webpackChunkName: "component---src-pages-achievements-js" */),
  "component---src-pages-articles-and-stories-js": () => import("./../../../src/pages/articles-and-stories.js" /* webpackChunkName: "component---src-pages-articles-and-stories-js" */),
  "component---src-pages-certificate-js": () => import("./../../../src/pages/certificate.js" /* webpackChunkName: "component---src-pages-certificate-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-festival-and-events-js": () => import("./../../../src/pages/festival-and-events.js" /* webpackChunkName: "component---src-pages-festival-and-events-js" */),
  "component---src-pages-form-js": () => import("./../../../src/pages/form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-harsh-competitions-[id]-index-js": () => import("./../../../src/pages/harsh/competitions/[id]/index.js" /* webpackChunkName: "component---src-pages-harsh-competitions-[id]-index-js" */),
  "component---src-pages-harsh-competitions-add-js": () => import("./../../../src/pages/harsh/competitions/add.js" /* webpackChunkName: "component---src-pages-harsh-competitions-add-js" */),
  "component---src-pages-harsh-competitions-index-js": () => import("./../../../src/pages/harsh/competitions/index.js" /* webpackChunkName: "component---src-pages-harsh-competitions-index-js" */),
  "component---src-pages-harsh-competitors-[id]-index-js": () => import("./../../../src/pages/harsh/competitors/[id]/index.js" /* webpackChunkName: "component---src-pages-harsh-competitors-[id]-index-js" */),
  "component---src-pages-harsh-competitors-index-js": () => import("./../../../src/pages/harsh/competitors/index.js" /* webpackChunkName: "component---src-pages-harsh-competitors-index-js" */),
  "component---src-pages-harsh-events-[id]-index-js": () => import("./../../../src/pages/harsh/events/[id]/index.js" /* webpackChunkName: "component---src-pages-harsh-events-[id]-index-js" */),
  "component---src-pages-harsh-events-add-js": () => import("./../../../src/pages/harsh/events/add.js" /* webpackChunkName: "component---src-pages-harsh-events-add-js" */),
  "component---src-pages-harsh-events-edit-[id]-index-js": () => import("./../../../src/pages/harsh/events/edit/[id]/index.js" /* webpackChunkName: "component---src-pages-harsh-events-edit-[id]-index-js" */),
  "component---src-pages-harsh-events-index-js": () => import("./../../../src/pages/harsh/events/index.js" /* webpackChunkName: "component---src-pages-harsh-events-index-js" */),
  "component---src-pages-harsh-index-js": () => import("./../../../src/pages/harsh/index.js" /* webpackChunkName: "component---src-pages-harsh-index-js" */),
  "component---src-pages-harsh-schools-[id]-index-js": () => import("./../../../src/pages/harsh/schools/[id]/index.js" /* webpackChunkName: "component---src-pages-harsh-schools-[id]-index-js" */),
  "component---src-pages-harsh-schools-add-js": () => import("./../../../src/pages/harsh/schools/add.js" /* webpackChunkName: "component---src-pages-harsh-schools-add-js" */),
  "component---src-pages-harsh-schools-index-js": () => import("./../../../src/pages/harsh/schools/index.js" /* webpackChunkName: "component---src-pages-harsh-schools-index-js" */),
  "component---src-pages-harsh-users-[id]-index-js": () => import("./../../../src/pages/harsh/users/[id]/index.js" /* webpackChunkName: "component---src-pages-harsh-users-[id]-index-js" */),
  "component---src-pages-harsh-users-add-js": () => import("./../../../src/pages/harsh/users/add.js" /* webpackChunkName: "component---src-pages-harsh-users-add-js" */),
  "component---src-pages-harsh-users-index-js": () => import("./../../../src/pages/harsh/users/index.js" /* webpackChunkName: "component---src-pages-harsh-users-index-js" */),
  "component---src-pages-history-of-anjanilal-js": () => import("./../../../src/pages/history-of-anjanilal.js" /* webpackChunkName: "component---src-pages-history-of-anjanilal-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-meet-us-js": () => import("./../../../src/pages/meet-us.js" /* webpackChunkName: "component---src-pages-meet-us-js" */),
  "component---src-pages-members-js": () => import("./../../../src/pages/members.js" /* webpackChunkName: "component---src-pages-members-js" */),
  "component---src-pages-palaces-to-visit-js": () => import("./../../../src/pages/palaces-to-visit.js" /* webpackChunkName: "component---src-pages-palaces-to-visit-js" */),
  "component---src-pages-pooja-and-abhishek-js": () => import("./../../../src/pages/pooja-and-abhishek.js" /* webpackChunkName: "component---src-pages-pooja-and-abhishek-js" */),
  "component---src-pages-pratiyogita-js": () => import("./../../../src/pages/pratiyogita.js" /* webpackChunkName: "component---src-pages-pratiyogita-js" */),
  "component---src-pages-stotras-and-aarti-js": () => import("./../../../src/pages/stotras-and-aarti.js" /* webpackChunkName: "component---src-pages-stotras-and-aarti-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-temples-js": () => import("./../../../src/pages/temples.js" /* webpackChunkName: "component---src-pages-temples-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-blog-detail-template-js": () => import("./../../../src/templates/BlogDetailTemplate.js" /* webpackChunkName: "component---src-templates-blog-detail-template-js" */),
  "component---src-templates-event-detail-template-js": () => import("./../../../src/templates/EventDetailTemplate.js" /* webpackChunkName: "component---src-templates-event-detail-template-js" */),
  "component---src-templates-mandir-template-js": () => import("./../../../src/templates/MandirTemplate.js" /* webpackChunkName: "component---src-templates-mandir-template-js" */),
  "component---src-templates-place-detail-template-js": () => import("./../../../src/templates/PlaceDetailTemplate.js" /* webpackChunkName: "component---src-templates-place-detail-template-js" */),
  "component---src-templates-results-detail-template-js": () => import("./../../../src/templates/ResultsDetailTemplate.js" /* webpackChunkName: "component---src-templates-results-detail-template-js" */),
  "component---src-templates-service-detail-template-js": () => import("./../../../src/templates/ServiceDetailTemplate.js" /* webpackChunkName: "component---src-templates-service-detail-template-js" */),
  "component---src-templates-stotras-detail-template-js": () => import("./../../../src/templates/StotrasDetailTemplate.js" /* webpackChunkName: "component---src-templates-stotras-detail-template-js" */)
}

